angular.module('app').filter('listsort', function() {
    return function(listing,sortValues,sortPriorities) {
    	if(!listing) return listing;
        var trace = angular.trace;
        var sortClasses = ["none","asc","desc"];

        listing.sort(defaultSort);
        if(sortPriorities && sortPriorities.length) listing.sort(sort);

        function sort(a,b) {

            for(var i = 0, count = sortPriorities.length; i < count; i++) {
                var id = sortPriorities[i];
                var type = sortValues[id];
                var className = sortClasses[type];

                var val1 = a[id];
                var val2 = b[id];

                if(!isNaN(parseFloat(val1))) val1 = parseFloat(val1);
                if(!isNaN(parseFloat(val2))) val2 = parseFloat(val2);

                if(className === "asc") {
                    if(val1 > val2) return 1;
                    else if(val1 < val2) return -1;
                }
                else if(className === "desc") {
                    if(val1 < val2) return 1;
                    else if(val1 > val2) return -1;
                }
            }
        }

        function defaultSort(a,b){
            if(parseInt(a.id) > parseInt(b.id)) return 1;
            else return -1;
        }

    	return listing;
    };
});