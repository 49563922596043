(function () {'use strict';}());
angular.module('app').service('AppService', function($http,$filter)
{
	var trace = angular.trace;
	var self = {};

	self.fetchOverview = function(){
		return $http.post("php/services/AssetDatabaseService.php",{request:"fetchOverview"}).success(onFetchOverview);
	};

	self.fetchProductDetails = function(productId){
		return $http.post("php/services/AssetDatabaseService.php",{request:"fetchDetails",productId:productId}).success(onFetchDetails);
	};

	self.fetchAllProductDetails = function() {
		return $http.post("php/services/AssetDatabaseService.php",{request:"fetchAllProductDetails"}).success(onFetchAllDetails);
	};

	self.saveMetadata = function(label,list,deletions) {
		return $http.post("php/services/AssetDatabaseService.php",{request:"saveMetadata",label:label,list:list,deletions:deletions}).success(onUpdateMetadata);
	};

	self.saveProduct = function(product,type) {
		return $http.post("php/services/AssetDatabaseService.php",{request:"saveProduct",product:product,type:type}).success(onSaveProduct);
	};

	self.fetchUsage = function(type){
		return $http.post("php/services/AssetDatabaseService.php",{request:"fetchUsage",type:type});
	};

	self.importKeywords = function(words) {
		return $http.post("php/services/AssetDatabaseService.php",{request:"importKeywords",words:words});
	};

	function onFetchOverview(result){
		var i,count,product;

		for(i = 0, count = result.products.length; i < count; i++){
			product = result.products[i];
			if(product.urlIds) product.urlIds = product.urlIds.split(",");
			if(product.urls) product.urls = product.urls.split(",");
			product.line = extractReferenceById(product.lineId,result.productLines,true);
			product.brand = extractReferenceById(product.brandId,result.brands,true);
			product.aoc = extractReferenceById(product.aocId,result.countries,true);
			product.coc = extractReferenceById(product.cocId,result.countries,true);
		}

		self.variants = [{id:1,name:'1 Block'},{id:2,name:'2 Block'},{id:3,name:'3 Block'},{id:4,name:'4 Block'},{id:5,name:'5 Block'}];

		self.products = result.products;
		self.productLines = result.productLines;
		self.productBrands = result.brands;
		self.countries = result.countries;
		self.keywords = result.keywords;
		self.audiences = result.audiences;
		self.ageGroups = result.ageGroups;
		self.focusAreas = result.focusAreas;
		self.distributors = result.distributors;
		self.awards = result.awards;
		self.brands = result.brands;
	}

	function extractReferenceById(id,source,returnOne){
		var matches = $filter('type')(source,"id",id);
		if(matches.length && returnOne === true) return matches[0];
		return matches;
	}
	function extractReferenceBy(name,value,source,returnOne){
		var matches = $filter('type')(source,name,value);
		if(matches.length && returnOne === true) return matches[0];
		return matches;
	}

	function onFetchDetails(result){
		var i,count;

		for(i = 0, count = result.descriptions.length; i < count; i++){
			var description = result.descriptions[i];
			description.variant = extractReferenceBy("name",description.variant,self.variants,true);
		}

		//self.currentAudiences = result.audiences.map(mapAudienceReference);
		self.currentProductKeywords = createMap("keywords",result.keywords);
		self.currentProductAudiences = createMap("audiences",result.audiences);
		self.currentProductAgeGroups = createMap("ageGroups",result.ageGroups);
		self.currentProductFocusAreas = createMap("focusAreas",result.focusAreas);
		self.currentProductDescriptions = result.descriptions;
		self.currentProductAwards = createMap("awards",result.awards);

		trace(result);
	}

	function onFetchAllDetails(result) {
		self.currentDetailLookup = result.products;
		trace(result.products);
	}

	function onSaveProduct(data){
		var result = data;
		var i,count;

		for(i = 0, count = result.descriptions.length; i < count; i++){
			var description = result.descriptions[i];
			description.variant = extractReferenceBy("name",description.variant,self.variants,true);
		}

		//self.currentAudiences = result.audiences.map(mapAudienceReference);
		self.currentProductKeywords = createMap("keywords",result.keywords);
		self.currentProductAudiences = createMap("audiences",result.audiences);
		self.currentProductAgeGroups = createMap("ageGroups",result.ageGroups);
		self.currentProductFocusAreas = createMap("focusAreas",result.focusAreas);
		self.currentProductDescriptions = result.descriptions;
		self.currentProductAwards = createMap("awards",result.awards);
	}
	function onUpdateMetadata(result){
		self[result.type] = result.list;
	}

	function createMap(type,pkeys){
		var lookup = {};
		var i,count,keyword;
		for(i = 0, count = self[type].length; i < count; i++){
			keyword = self[type][i];
			lookup[keyword.id] = false;
		}
		for(i = 0, count =pkeys.length; i < count; i++){
			keyword = pkeys[i];
			lookup[keyword.id] = true;
		}
		return lookup;
	}

	return self;
});