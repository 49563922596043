(function () {'use strict';}());
angular.module("app").directive('lineListingView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/line-listing.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
      }  
    };
  });