angular.module('app').filter('type', function() {
    return function(listing,field,value) {
    	if(!listing) return listing;
        var trace = angular.trace;
    	var results = [];

        for(var i = 0, count = listing.length; i < count; i++){
            var record = listing[i];
            if(record[field] === value) results.push(record);
        }

    	return results;
    };
});