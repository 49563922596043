(function () {'use strict';}());

angular.module('app')
  .controller('ProductFormController', function ($scope,$window,$filter) {

  	var trace = angular.trace;

  	$scope.addUrl = function(){
      if(!$scope.currentProduct.urls) {
        $scope.currentProduct.urls = [];
        $scope.currentProduct.urlIds = [];
      }

			$scope.currentProduct.urls.push("");
			$scope.currentProduct.urlIds.push(null);
      $scope.currentProduct.dirty = true;
  	};

  	$scope.removeUrl = function(index){
      var id = $scope.currentProduct.urlIds.splice(index,1);
			$scope.currentProduct.urls.splice(index,1);
      if(!$scope.currentProduct.urlDeletions) $scope.currentProduct.urlDeletions = [];
      if(id[0]) $scope.currentProduct.urlDeletions.push(id[0]);
      $scope.currentProduct.dirty = true;
  	};

    $scope.addDescription = function(variant) {
      var source;
      if(!$scope.currentProduct.descriptions.length) {
        source = {id:null,description:null,priority:0,variant:$scope.variants[variant]};
      }
      else {
        source = angular.copy($scope.currentProduct.descriptions[0]);
        for(var prop in source){
          if(prop == "variant") source[prop] = $scope.variants[variant];
          else if(prop == "priority") source[prop] = 0;
          else source[prop] = null;
        }
      }
      $scope.currentProduct.descriptions.push(source); 

      var variantDescriptions = $filter('description')($scope.currentProduct.descriptions,$scope.variants[variant].name);
      variantDescriptions.sort(sortDescriptions);
      source.priority = (parseFloat(variantDescriptions[0].priority) + 1).toString();
      $scope.currentProduct.dirty = true;

      trace("adding descriptions",$scope.currentProduct.descriptions);
    };

    function sortDescriptions(a,b){
      if(a.priority > b.priority) return -1;
      if(a.priority < b.priority) return 1;
      return 0;
    }

    $scope.removeDescription = function(description) {

      for(var i = 0, count = $scope.currentProduct.descriptions.length; i < count; i++){
        var reference  = $scope.currentProduct.descriptions[i];
        trace(reference,description,reference == description,"-----");
        if(reference !== description) continue;
        $scope.currentProduct.descriptions.splice(i,1);
        if(!$scope.currentProduct.descriptionDeletions) $scope.currentProduct.descriptionDeletions = [];
        if(description.id) $scope.currentProduct.descriptionDeletions.push(description.id);
        break;
      }

      
    };
});