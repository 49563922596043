angular.module('app').filter('description', function() {
    return function(listing,variant) {
    	if(!listing) return listing;
        var trace = angular.trace;
    	var results = [];

    	for(var i = 0, count = listing.length; i < count; i++){
    		var item = listing[i];
    		if(item.variant.name === variant) results.push(item);
    	}

    	return results;
    };
});