(function () {'use strict';}());
angular.module('app')
  .controller('AppController', function ($scope,$window,$timeout,AppService) {
  	var trace = angular.trace;

  	$scope.MV_OVERVIEW = 1;
  	$scope.SV_LINE_LISTING = 1;

    $scope.MV_PRODUCT_DETAIL = 2;
    $scope.SV_PRODUCT_DETAIL_OVERVIEW = 2;
    $scope.SV_PRODUCT_DETAIL_DESCRIPTIONS = 3;
    $scope.SV_PRODUCT_DETAIL_DIMENSIONS = 4;
    $scope.SV_PRODUCT_DETAIL_AUDIENCE = 5;
    $scope.SV_PRODUCT_DETAIL_AMAZON = 6;

    $scope.MV_META = 3;
    $scope.SV_META_AUDIENCES = 1;
    $scope.SV_META_AGE_GROUPS = 2;
    $scope.SV_META_FOCUS_AREAS = 3;
    $scope.SV_META_AWARDS = 4;
    $scope.SV_META_KEYWORDS = 5;
    $scope.SV_META_DISTRIBUTORS = 6;
    $scope.SV_META_BRANDS = 7;
    $scope.SV_META_PRODUCT_LINES = 8;
    $scope.SV_META_COUNTRIES = 9;

    $scope.MV_LANDING = 4;

  	$scope.currentMasterView = null;
  	$scope.currentSubView = null;
  	$scope.products = [];
    $scope.productLines = [];
    $scope.ready = false;
    $scope.containerStyles = {};

    $scope.listView = false;

    $scope.currentUpload = {};

    $scope.currentSearch = {term:null,group:"all"};

    $scope.sortClasses = ["none","asc","desc"];
    $scope.sortVals = {};
    $scope.sortPriorities = [];

    $scope.selectedColumns = {id:true,name:true,msrp:true,map:true,sku:true};

  	AppService.fetchOverview().then(initialize);

    $scope.toggleListView = function() {
      $scope.listView = !$scope.listView;

      if($scope.listView) loadAllDetails();
    };

    $scope.returnToProductListing = function(){
      /*if($scope.currentProduct && $scope.currentProduct.dirty) {
        return $window.alert("Please save or revert your changes to the current product before you proceed.");
      }*/

      if($scope.listView === true) {
         $scope.containerStyles.saving = true;
          AppService.fetchAllProductDetails().then(function(){
            $scope.detailLookup = AppService.currentDetailLookup;
            $scope.containerStyles.saving = false;
            $scope.setCurrentView($scope.MV_OVERVIEW,$scope.SV_LINE_LISTING);
          });
      }
      else {
        $scope.setCurrentView($scope.MV_OVERVIEW,$scope.SV_LINE_LISTING);
      }

      
    };

    $scope.cloneProduct = function(product){

      trace("product",product);
      var clone = {};
      for(var prop in product) {
        clone[prop] = product[prop];
      }
      clone.id = null;
      clone.sku = null;
      clone.urlDeletions = clone.urlIds;
      clone.name = clone.name + " Clone";
      clone.urlIds = clone.urlIds.map(function(){return null;});
      clone.descriptions.forEach(function(element){element.id = null;});

      AppService.saveProduct(clone,"new").then(function(result){
        clone.id = result.data.input.id;
        clone.descriptions = AppService.currentProductDescriptions;
        clone.keywords = AppService.currentProductKeywords;
        clone.audiences = AppService.currentProductAudiences;
        clone.ageGroups = AppService.currentProductAgeGroups;
        clone.focusAreas = AppService.currentProductFocusAreas;
        clone.awards = AppService.currentProductAwards;
        clone.urlIds = result.data.input.urlIds;
        $scope.products.push(clone);
        $window.location = "/?pl=true";
      });
    };


  	$scope.setCurrentView = function(mv,sv,preventCrossfade){
      if($scope.containerStyles.fadeDown === true) return;
     
      if(preventCrossfade){
        $scope.currentMasterView = mv;
        $scope.currentSubView = sv;
        $scope.containerStyles.landing = $scope.currentMasterView === $scope.MV_LANDING;
        return;
      }

      $scope.containerStyles.fadeDown = true;
      $timeout(function(){
        $scope.currentMasterView = mv;
        $scope.currentSubView = sv;
        $scope.containerStyles.landing = $scope.currentMasterView === $scope.MV_LANDING;
        $timeout(function(){$scope.containerStyles.fadeDown = false;},250);
      },250);
  		
  	};

  	$scope.setCurrentProduct = function(product){
      $scope.currentBackup = product;
  		AppService.fetchProductDetails(product.id).then($scope.setProductDetailsView);
  	};

  	$scope.setProductDetailsView = function(result){
      $scope.currentBackup.descriptions = AppService.currentProductDescriptions;
      $scope.currentBackup.keywords = AppService.currentProductKeywords;
      $scope.currentBackup.audiences = AppService.currentProductAudiences;
      $scope.currentBackup.ageGroups = AppService.currentProductAgeGroups;
      $scope.currentBackup.focusAreas = AppService.currentProductFocusAreas;
      $scope.currentBackup.awards = AppService.currentProductAwards;
      $scope.currentProduct = createSavable($scope.currentBackup);
      $scope.setCurrentView($scope.MV_PRODUCT_DETAIL,$scope.SV_PRODUCT_DETAIL_OVERVIEW);
  	};

    $scope.ucase = function(label){
      return label.charAt(0).toUpperCase() + label.substring(1);
    };

    $scope.updateList = function(key,value) {
      $scope[key] = value;
    };

    $scope.updateMaps = function(type) {
      AppService[type] = $scope[type];
    };

    $scope.save = function(label,deletions) {
      var list = $scope[label];
      list = list.filter(function(element){return element.updated;});
      deletions = deletions.filter(function(element){ return element.id ? true : false;});
      deletions = deletions.map(function(element){return element.id;});
      $scope.containerStyles.saving = true;
      return AppService.saveMetadata(label,list,deletions.join(",")).then(onSave);
    };

    $scope.updateProduct = function(product,overrideDefaultBehavior) {

      if(overrideDefaultBehavior === true) {
        $scope.containerStyles.saving = true;
        AppService.saveProduct(product,"update").then(function(){
          $scope.containerStyles.saving = false;
        });
        return;
      }


      $scope.containerStyles.saving = true;
      product.lineId = product.line.id;
      product.brandId = product.brand.id;
      product.components_origin_country = product.coc.id;
      product.assembly_origin_country = product.aoc.id;

      return AppService.saveProduct(product,"update").then(function(){
        var index = $scope.products.indexOf($scope.currentBackup);
        $scope.products.splice(index,1,product);
        product.dirty = false;
        $scope.currentBackup = product;
        $scope.currentBackup.descriptions = AppService.currentProductDescriptions;
        $scope.currentBackup.keywords = AppService.currentProductKeywords;
        $scope.currentBackup.audiences = AppService.currentProductAudiences;
        $scope.currentBackup.ageGroups = AppService.currentProductAgeGroups;
        $scope.currentBackup.focusAreas = AppService.currentProductFocusAreas;
        $scope.currentBackup.awards = AppService.currentProductAwards;
        $scope.currentProduct = createSavable($scope.currentBackup);

        trace("setting currentProduct",$scope.currentProduct);
        
        $scope.containerStyles.saving = false;
      });
    };

    $scope.deleteProduct = function(product) {
      var result = $window.confirm("Are you sure want to delete this product? This action cannot be undone");
      if(!result) return null;
      $scope.containerStyles.saving = true;
      return AppService.saveProduct(product,"deletion").then(function(){
        $scope.products.splice($scope.products.indexOf(product),1);
        $scope.setCurrentView($scope.MV_OVERVIEW,$scope.SV_LINE_LISTING);
        $scope.containerStyles.saving = false;
      });
    };

    $scope.revertProduct = function(product) {
      $scope.currentProduct.dirty = false;
      $scope.currentProduct = createSavable($scope.currentBackup);
    };

    function onSave(result) {
      $scope[result.data.type] = AppService[result.data.type];
      $scope.containerStyles.saving = false;
    }

    function createSavable(product) {
      var clone = {};
      
      for(var prop in product) {
        clone[prop] = product[prop];
      }

      clone.audiences = angular.copy(clone.audiences);
      clone.focusAreas = angular.copy(clone.focusAreas);
      clone.keywords = angular.copy(clone.keywords);
      
      if(clone.urlIds) clone.urlIds = clone.urlIds.concat();
      if(clone.urls) clone.urls = clone.urls.concat();
      if(clone.descriptions) clone.descriptions = angular.copy(clone.descriptions);

      return clone;
    }

  	function initialize(){
  		$scope.products =  AppService.products;
      $scope.productLines = AppService.productLines;
      $scope.productBrands = AppService.productBrands;
      $scope.countries = AppService.countries;
      $scope.variants = AppService.variants;
      $scope.keywords = AppService.keywords;
      $scope.ageGroups = AppService.ageGroups;
      $scope.audiences = AppService.audiences;
      $scope.focusAreas = AppService.focusAreas;
      $scope.awards = AppService.awards;
      $scope.distributors = AppService.distributors;
      $scope.brands = AppService.brands;

      $scope.ready = true;
      if(__FIRST_PAGE__ === "product-listing") $scope.setCurrentView($scope.MV_OVERVIEW,$scope.SV_LINE_LISTING);
      $scope.setCurrentView($scope.MV_LANDING);
  	}

    function loadAllDetails() {
      $scope.containerStyles.saving = true;
      AppService.fetchAllProductDetails().then(function(){
        $scope.detailLookup = AppService.currentDetailLookup;
        $scope.containerStyles.saving = false;
      });
    }


  });
