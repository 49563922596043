(function () {'use strict';}());

angular.module('app')
  .controller('MetaFormController', function ($scope,$window,AppService) {
  	var trace = angular.trace;
    var initialized = false;
    var deletions = [];
    var rand = Math.random();

    if($scope.target) $scope.$watch("ready",onReady);

  	$scope.addObject = function(event,label){

      if(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }

      var ucase = "temp"+$scope.ucase(label);

      var blank = angular.copy($scope[ucase][0]);
      for(var prop in blank) {
        blank[prop] = null;
      }
      blank.updated = true;
      $scope[ucase].push(blank);
  	};

     $scope.importKeywords = function() {

      var f = document.getElementById('keyword-file').files[0],
        r = new FileReader();
        r.onloadend = function(e) {
          var data = e.target.result;
          var words = data.split(/\n/gim).map(trim);
          var components = [];
          var existing = $scope.tempKeywords.map(function(element){return element.word.toLowerCase();});
          
          for (var i = 0, count = words.length; i < count; i++) {
            var word = words[i];
            var split = word.split(/\s+/gim).filter(filterEmpties);
            if(split.length <= 1) continue;
            components = components.concat(split.filter(filterExclusions,{list:words,components:components}));
          }
          components.forEach(trim);
          words = words.concat(components);
          words = words.filter(filterDupes,existing);
          
          $scope.$apply(function(){
            for(var i = 0, count = words.length; i < count; i++) {
              $scope.tempKeywords.push({id:null,word:words[i],updated:true});
            }
            //AppService.importKeywords(words);
          });
          
        };

        r.readAsBinaryString(f);
    };

    function filterDupes(element){
     for(var i = 0, count = this.length; i < count; i++){
      if(element.toLowerCase() === this[i].toLowerCase()) return false;
     }
     return true;
    }

    function trim(element){
      return element.replace(/\s+/gim," ").replace(/^\s+/gim,"").replace(/\s+$/gim,"");
    }

    function filterEmpties(element) {
      if(element.replace(/\s+/gim,"").length === 0) return false;
      return true;
    }

    function filterExclusions(element) {
      var exclusions = ["a","is","not","will","won't","are","was","am","an","the","some","with", "at", "from", "into", "during", "including", "until", "against", "among", "throughout", "despite", "towards", "upon", "concerning", "of", "to", "in", "for", "on", "by", "about", "like", "through", "over", "before", "between", "after", "since", "without", "under", "within", "along", "following", "across", "behind", "beyond", "plus", "except", "but", "up", "out", "around", "down", "off", "above", "near"];
      if(this.list.indexOf(element) !== -1) return false;
      if(this.components.indexOf(element) !== -1) return false;
      if(exclusions.indexOf(element) !== -1) return false;
      if(element.replace(/\s+/gim,"").length === 0) return false;
      return true;
    }

  	$scope.deleteObject = function(event,label,index){
  		event.stopImmediatePropagation();
  		event.preventDefault();
  		var ucase = "temp"+$scope.ucase(label);
      if($scope[ucase].length <= 1) return $window.alert("You must have one item in the list at all times");
      deletions.push($scope[ucase].splice(index,1)[0]);
  	};

  	$scope.cancel = function(event,label) { 
  		event.stopImmediatePropagation();
  		event.preventDefault();

      var original = $scope[label];
      var ucase = "temp"+$scope.ucase(label);
      deletions = [];
      $scope[ucase] = original.concat();
      original.forEach(reset);
  	};

  	$scope.commit = function(label) {
      var ucase = "temp"+$scope.ucase(label);
  		$scope.updateList(label,$scope[ucase].concat());
      $scope.updateMaps(label);
      $scope.save(label,deletions).then(onCommit.bind({label:label}));
      deletions = [];
  	};

    $scope.fetchUsage = function(type){
      return AppService.fetchUsage(type);
    };

    $scope.changeToProduct = function(pid) {
      var product;
      for(var i = 0,count = $scope.products.length; i < count; i++){
        if($scope.products[i].id === pid) product = $scope.products[i];
      }
      if(!product) return $window.alert("Error loading product!");
      $scope.setCurrentProduct(product);
    };

    function reset(element) {
      for(var prop in element._source){
        element[prop] = element._source[prop];
      }
    }

    function onCommit() {
      createSavable(this.label);
    }

    function onReady(value) {
      if(!value || initialized) return;
      initialized = true;
      createSavable($scope.target);
    }

    function createSavable(label) {
      var source = $scope[label];
      var ucase = $scope.ucase(label);
      $scope["temp"+ucase] = source.concat();
      $scope["temp"+ucase].forEach(function(element){
        delete element._source;
        element._source = angular.copy(element);
      });
    }
 });