angular.module('app').filter('productsearch', function() {
    return function(listing,search) {
    	if(!listing) return listing;
        var trace = angular.trace;
    	var results = [];

    	if(!search.term || !search.term.length) return listing;

    	for(var i = 0, count = listing.length; i < count; i++){
    		var item = listing[i];
    		var bn = item.brandName.replace(/\s+/gim,"").toLowerCase();
    		var ln = item.lineName.replace(/\s+/gim,"").toLowerCase();
    		var nm = item.name.replace(/\s+/gim,"").toLowerCase();

    		var term = search.term.replace(/\s+/gim,"").toLowerCase();
    		if(bn.match(term) || ln.match(term) || nm.match(term)) results.push(item);
    	}


    	return results;
    };
});