(function () {'use strict';}());
angular.module("app").directive('metaKeywords', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/meta-keywords.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        $scope.fetching = true;
        $scope.fetchUsage('keywords').then(onFetchUsage);
        $scope.usage = null;

        $scope.renderUsage = function(id,name,event){
          event.preventDefault();
          event.stopImmediatePropagation();
        	$scope.currentUsage = $scope.usage[id];
        	$scope.currentName = name;
        };


        function onFetchUsage(result) {

        	var usage = result.data.usage;

        	for(var prop in usage) {
        		var text = usage[prop];
        		var products = text ? text.split(",") : [];
	        	products = products.map(function(element){
	        		var id = element.replace(/^(.*?):.*?$/gim,"$1");
	        		var label = element.replace(/^.*?:(.*?)$/gim,"$1");
	        		return {id: id, label: label};
	        	});
	        	usage[prop] = products;
        	}

        	$scope.usage = usage;
        	$scope.fetching = false;
        }
      }  
    };
  });