(function () {'use strict';}());
angular.module("app").directive('lineListingLvView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/line-listing-lv.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $scope.addingColumns = false;


        $timeout(function(){
            $(".table").resizableColumns({store: window.store});
        },1000);

        $scope.showColumnModal = function() {
        	$scope.addingColumns = true;
        };

        $scope.hideColumnModal = function(){
        	$scope.addingColumns = false;
        };

        $scope.columnExists = function(id) {
        	return $scope.selectedColumns[id];
        };

        $scope.onInputBlur = function(product) {
        	$scope.updateProduct(product,true);
        };

        $scope.updateTableDrag = function() {
            $(".table").resizableColumns("destroy").resizableColumns({store: window.store});
        };

        $scope.toggleSortValue = function(id) {
        	if(!$scope.sortVals[id]) $scope.sortVals[id] = 0;
        	$scope.sortVals[id] = $scope.sortVals[id] + 1;
        	if($scope.sortVals[id] == 3) $scope.sortVals[id] = 0;

        	if($scope.sortPriorities.indexOf(id) === -1) $scope.sortPriorities.push(id);
        	else if($scope.sortPriorities.indexOf(id) !== -1 && $scope.sortVals[id] === 0) {
        		$scope.sortPriorities.splice($scope.sortPriorities.indexOf(id),1);
        	}
        };
      }  
    };
  });