angular.module('app').filter('line', function() {
    return function(listing,type,rid) {
    	if(!listing) return listing;
        var trace = angular.trace;
    	var results = [];
        var lines = [];
        var product,line,i,count;

        if(type === "meta"){
            for(i = 0, count = listing.length; i < count; i++)
            {
                product = listing[i];
                line = product.lineId;
                
                if(lines.indexOf(line) === -1){
                    lines.push(line);
                    results.push(product);
                }
            }

            results.sort(function(a,b){
                if(a.lineName > b.lineName) return 1;
                if(a.lineName < b.lineName) return -1;
                return 0;
            });
        }
        else if (type === "subs"){
            for(i = 0, count = listing.length; i < count; i++)
            {
                product = listing[i];
                line = product.lineId;
                if(line === rid) results.push(product);
            }

            results.sort(function(a,b){
                if(a.name > b.name) return 1;
                if(a.name < b.name) return -1;
                return 0;
            });
        }
    	
         

        

    	return results;
    };
});